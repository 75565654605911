import React from "react";
import YouTube from "react-youtube";
//import ReactPlayer from 'react-player';
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import {Accordion,  AccordionSummary, AccordionDetails } from "@material-ui/core/";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ExpansionPanel = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})( AccordionSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionDetails);

class FAQEVCOFFICE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "panel0",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(panel, expanded) {
    this.setState(
      expanded === panel ? { expanded: "panel0" } : { expanded: panel }
    );
  }

  render() {
    const { expanded } = this.state;
    const opts = {
      height: "390",
      width: "640",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };
    return (
      <div>
        <h3 style={{ textAlign: "center", color: "#0178e2" }}>
          Διαβάστε απαντήσεις για συνηθισμένες & συχνές ερωτήσεις.
        </h3>
		<ExpansionPanel
          expanded={expanded === "getevcoffice"}
          onChange={() => this.handleChange("getevcoffice", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="getevcoffice"
            id="getevcoffice"
          >
            <Typography>
              Πως μπορώ <b>να αποκτήσω το
              EVCOFFICE Τηλεφωνικό κέντρο; </b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="getevcoffice">
                Με την αυτόματη εγγραφή η υπηρεσία είναι διαθέσιμη για χρήση
                μέσα σε λίγα λεπτά!
                <ol>
                  <li>
                    Διαλέξτε το πακέτο που σας ταιριάζει καθώς και τον αριθμό χρηστών
                    στην επιχείρηση σας.
                  </li>
                  <li>Επιλέξτε τηλεφωνικό αριθμό.</li>
                  <li>
                    Προσθέστε επιπλέον προγράμματα χρόνου ομιλίας ανάλογα των αναγκών σας,
                    για μεγαλύτερη οικονομία.
                  </li>
                  <li>
                    * Ο χρόνος ομιλίας μοιράζεται μεταξύ ΟΛΩΝ των χρηστών του
                    τηλεφωνικού κέντρου.
                  </li>
                  <li>
                    Με την ολοκλήρωση Θα λάβετε ενα email με το όνομα χρήστη
                    (username) και το συνθηματικό (password) για το EVCOFFICE
                    τηλεφωνικό κέντρο σας.
                  </li>
                </ol>
                <p>
                  Μετά την επιλογή Ελληνικού αριθμού τηλεφώνου, θα λάβετε ένα
                  email με τα απαραίτητα στοιχεία που χρειάζονται για την ταυτοποίηση του.
                  Ο αριθμός που έχετε διαλέξει έχει δεσμευτεί, αλλά παραμένει ανενεργός 
				  μέχρι να μας στείλετε τα απαραίτητα στοιχεία ταυτοποίησης. Μπορείτε να 
				  μας τα στείλετε μέσω α) email στο <b>support@hellasfon.com</b>, μέσω <b>Fax - 
				  2811300308</b> ή
                  μέσα από την <b>EVCOFFICE εφαρμογή</b>.Τραβήξτε φωτογραφίες τα
                  στοιχεία σας και στείλτε τα σαν επισυναπτόμενα, από το μενού
                  <b> Feedback</b> της εφαρμογής. Με την παραλαβή των στοιχείων,
                  ενεργοποιείται αμέσως ο αριθμός σας.
                </p>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
<ExpansionPanel
          expanded={expanded === "getsip"}
          onChange={() => this.handleChange("getsip", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="getsip"
            id="getsip"
          >
            <Typography>
              Πώς μπορώ να γίνω συνδρομητής της υπηρεσίας <b>HELLASFON Sip Trunk;</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="getsip">
                <ol>
                  <b>Hellasfon SIPTRUNK</b>
                  <li>
                    Διαλέξτε πακέτο, τον αριθμό γραμμών SIP TRUNK ( 2, 10 ή 30
                    κανάλια φωνής) καθώς και τον αριθμό τηλεφώνου που επιθυμείτε.
                  </li>
                  <li>
                    Με την ολοκλήρωση, θα λάβετε ένα email με τον καινούργιο σας
                    αριθμό τηλεφώνου, το όνομα χρήστη (username) και το
                    συνθηματικό σας (password).
                  </li>
                  <li>Η υπηρεσία είναι έτοιμη για χρήση! </li>
                  <li>
                    Εισάγοντας στον εξοπλισμό σας τις ρυθμίσεις που σας έχουμε
                    στείλει, αμέσως μπορείτε να λάβετε και να κάνετε κλήσεις.
                  </li>
                </ol>
                <b>Για την ταυτοποίηση του Αριθμού τηλεφώνου σας</b>.
                <ul>
                  <li>
                    Για την ταυτοποίηση και ενεργοποίηση του αριθμού τηλεφώνου,
                    στείλτε μας με email, την ταυτότητά σας ( μπροστινή / πίσω όψη)
                    και ένα λογαριασμό ΔΕΚΟ. Δείτε αναλυτικά τα απαραίτητα{" "}
                    <a href="/docs/GREEK-DID-REQUIREMENTS.pdf">
                      {" "}
                      στοιχεία ταυτοποίησης
                    </a>
                    .
                  </li>
                  <li>
                    Για μεγαλύτερη ευκολία και συντομία, στείλτε μας τα στοιχεία
                    ταυτοποίησης σας ως συνημμένα από το μενού FEEDBACK της
                    Hellasfon (HF) εφαρμογής.
                  </li>
                  <li>
                    Μέχρι την ολοκλήρωση της ταυτοποίησης ο Ελληνικός αριθμός
                    που σας έχει εκχωρηθεί και θα παραμείνει δεσμευμένος για
                    εσάς.
                  </li>
                  <li>
                    Οσο αναμένουμε τα στοιχεία ταυτοποίησης μπορείτε να κάνετε
                    κανονικά χρήση της υπηρεσίας. Σε αυτό το στάδιο, η Hellasfon
                    θα χρησιμοποιεί προσωρινά τον υπάρχων αριθμό του κινητού σας, σαν
                    αναγνώριση (callerid) στις εξερχόμενες κλήσεις σας.
                  </li>
                  <li>
                    Μετά την ολοκλήρωση της ταυτοποίησης, αυτόματα θα
                    ενεργοποιηθεί και ο αριθμός σας.
                  </li>
                </ul>
              </div>
            </Typography>
          </ExpansionPanelDetails>
       </ExpansionPanel>
<ExpansionPanel
          expanded={expanded === "get-bline"}
          onChange={() => this.handleChange("get-bline", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="get-bline"
            id="get-bline"
          >
            <Typography>
              Πώς μπορώ να γίνω συνδρομητής της υπηρεσίας <b>HELLASFON Business
              Line;</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="get-bline">
                <b>Hellasfon Business line</b>
                <ol>
                  <li>Συμπληρώστε την αίτηση εγγραφής.</li>
                  <li>
                    Με την δημιουργία του λογαριασμού σας, θα λάβετε ένα email
                    με τον νέο σας αριθμό, το όνομα χρήστη (username) καθώς και
                    το συνθηματικό (password).
                  </li>
                  <li>
                    Κατεβάστε την Hellasfon εφαρμογή (HF), και κάντε log-in
                    χρησιμοποιώντας το όνομα χρήστη και το συνθηματικό που σας
                    έχουμε στείλει.
                  </li>
                </ol>
                Η υπηρεσία είναι έτοιμη για χρήση!<br></br> <br></br>
				<b>Για χρήση στο Γραφείο:</b>
                <ol>
                  <li>
                    Για υπηρεσία από την σταθερή συσκευή απαιτείται{" "}
                    <a href="https://shop.hellasfon.com/">VOIP συσκευή</a>.
                  </li>
                  <li>
                    Οι συνδρομητές Business Line - μπορούν να χρησιμοποιούν την
                    Hellasfon εφαρμογή (HF) για όλες τις Υπηρεσίες τους.
                  </li>
                  <li>
                    Η HellasFon συσκευή έρχεται προγραμματισμένη και συνδέεται
                    με 2 απλά βήματα: συνδέοντας την συσκευή στο ADSL ρούτερ. 
                    (<a href="https://shop.hellasfon.com/"> δείτε πώς </a>)
                  </li>
                </ol>
                Για την ταυτοποίηση του αριθμού.
                <ul>
                  <li>
                    Για την ταυτοποίηση και ενεργοποίηση του αριθμού τηλεφώνου,
                    στείλτε μας στο email την ταυτότητά σας ( μπροστινή / πίσω
                    όψη ) και ένα λογαριασμό ΔΕΚΟ. Δείτε αναλυτικά τα απαραίτητα
                    στοιχεία ταυτοποίησης.
                  </li>
                  <li>
                    Για μεγαλύτερη ευκολία και συντόμευση, βγάλτε μια φωτογραφία
                    τα στοιχεία ταυτοποίησης και στείλτε τα σαν συνημμένα από το
                    FEEDBACK της εφαρμογής.
                  </li>
                  <li>
                    Μέχρι την ολοκλήρωση της ταυτοποίησης ο Ελληνικός αριθμός
                    που σας έχει εκχωρηθεί θα παραμείνει δεσμευμένος για εσάς.
                  </li>
                  <li>
                    Περιμένοντας τα στοιχεία ταυτοποίησης μπορείτε να κάνετε
                    κανονική χρήση της υπηρεσίας. Σε αυτό το στάδιο, η Hellasfon
                    εφαρμογή χρησιμοποιεί προσωρινά τον αριθμό του κινητού σας
                    τηλεφώνου σαν αναγνώριση στης κλήσεις σας (callerid).
                  </li>
                </ul>
                Με την ολοκλήρωση της ταυτοποίησης, αυτόματα ενεργοποιείται ο
                αριθμός τηλεφώνου στον λογαριασμό σας.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "renew"}
          onChange={() => this.handleChange("renew", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="renew"
            id="renew"
          >
            <Typography>
              Πως <b>προσθέτω / ανανεώνω</b> μονάδες ομιλίας?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="renew">
              <b style={{color: 'red'}}>Με συνδρομή και πακέτο ομιλίας</b> – Οι Μονάδες  Ανανεώνονται Αυτόματα.<br />
                Με την αυτόματη εγγραφή σας η υπηρεσία είναι διαθέσιμη για χρήση
                μέσα σε λίγα λεπτά.
                <ol>
                  <li>Από το μενού <b>Λογαριασμός</b> επιλέξτε <b>Ανανέωση</b> (1) και στην συνέχεια τον <b>τρόπο πληρωμής</b> (2) <br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice01.jpg")}
                      title="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                    <br/>
                  </li>
                 
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
		
		<ExpansionPanel
          expanded={expanded === "ivr"}
          onChange={() => this.handleChange("ivr", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="ivr"
            id="ivr"
          >
            <Typography>
              Τι είναι το <b>IVR ? </b> (παράδειγμα χρήσης)
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel4">
              <b style={{color: 'red'}}>Το IVR (Interactive Voice Response) </b> είναι μια αυτοματοποιημένη διαδικασία η οποία διαχειριζεται
			  τις εισερχόμενες κλήσεις του τηλεφωνικού μας κέντρου χωρίς να χρειάζεται κάποια τηλεφωνήτρια ή γραμματέας για να τις δρομολογήσει
			  στο αντίστοιχο άτομο ή τμήμα. <br />
			    
                Ετσι ο πελάτης ακούει ηχητικά μηνύματα με τα οποία αλληεπιδράει, πατώντας απλώς πλήκτρα του τηλεφώνου του.<br /><br />
				Παράδειγμα <br />
				Έχετε καλέσει την εταιρία ΧΧΧ, για το τμήμα πωλήσεων πιέστε 1, για να το τμήμα υποστήριξης πελατών πιέστε 2,
				για το λογιστήριο πατήστε 3, κ.ο.κ  
                
                <ol>
                  <li>Από το μενού <b>Αυτόματη Υποδοχή</b> πατήστε το <b>+ για δημιουργία</b> και στην συμπληρώστε τα πεδία στην φόρμα <b>IVR σας</b> <br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/ivr1.jpg")}
                      title="Hellasfon Πως ρυθμίσετε το Τηλεφωνικό σας κέντρο - IVR Step1"
                    /><br />
					Από το μενού <b>Κατάλογος</b> πατήστε το <b>+ για δημιουργία</b> και στην συνέχεια εισάγετε τις επιλογές που θα έχει το <b>IVR σας</b> <br/><br/>
					<CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/ivr2.jpg")}
                      title="Hellasfon Πως ρυθμίσετε το Τηλεφωνικό σας κέντρο - IVR Step2"
                    />
                    <br/>
                  </li>
                 
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
		
        <ExpansionPanel
          expanded={expanded === "greetings"}
          onChange={() => this.handleChange("greetings", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="greetings"
            id="greetings"
          >
            <Typography>Πως προσθέτω / <b>ηχογραφώ μήνυμα προαπάντησης ?</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel5">
                <ol>
                  <li>Από το μενού <b>Το προφίλ μου</b> επιλέξτε <b>Χαιρετισμοί</b> (1) <br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice03.jpg")}
                      title="Hellasfon Κινητή, σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                    <br/>
                  </li>
                  <li>Πατήστε το <b>(+)</b> στην κάτω δεξιά γωνία (2) .<br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice04.jpg")}
                      title="Hellasfon Κινητή, Σταθερή τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                  </li>
                  <li>Ονομάστε τον χαιρετισμό και επιλέξτε τον επιθυμητό τρόπο δημιουργίας.<br/><br/>
                    <ol>
                      <li>
                        <b>ΑΠΟ ΤΟ ΑΡΧΕΊΟ</b>. Επιλέξτε ένα έτοιμο χαιρετισμό από τα αρχεία σας.<br/><br/>
                        <CardMedia
                          component="img"
                          alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                          image={require("../../assets/img/faq/faqevcoffice05.jpg")}
                          title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                        />
                      </li>
                      <br></br><li>
                        <b>RECORD.</b> Ηχογραφήστε ένα καινούριο χαιρετισμό<br/><br/>
                        <CardMedia
                          component="img"
                          alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                          image={require("../../assets/img/faq/faqevcoffice06.jpg")}
                          title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                        />
                      </li>
                      <br></br><li>
                        <b>ΚΕΙΜΕΝΟ ΣΕ ΟΜΙΛΙΑ</b> - μετατρέπει οτιδήποτε γράψετε σε φωνητικό μήνυμα. Επιλέξτε την φωνή και την γλώσσα που επιθυμείτε , γράψτε το κείμενο που θέλετε να ακουστεί. Επιλέξτε <b>ΜΕΤΑΤΡΟΠΗ</b> και στην συνέχεια <b>ΑΠΟΘΗΚΕΥΣΗ</b>.
						<br/><br/>
                        <CardMedia
                          component="img"
                          alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                          image={require("../../assets/img/faq/faqevcoffice07.jpg")}
                          title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                        />
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "addusers"}
          onChange={() => this.handleChange("addusers", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="addusers"
            id="addusers"
          >
            <Typography>
            Πως <b>προσθέτω καινούργιους χρήστες?</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="addusers">
                <ol>
                  <li>Από <b>Επιχείρηση</b> διαλέξτε <b>Χρήστες</b>.<br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice08.jpg")}
                      title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                    <br/>
                    (επομενη σελιδα)<br/>
                  </li>
                  <li>Επιλέξτε <b>‘+’></b> για να προσθέσεις τους χρήστες<br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice09.jpg")}
                      title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                  </li>
                  <li>Συμπληρώστε τα πεδία και <b>"Αποθηκευση"</b><br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice10.jpg")}
                      title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                    <b>Όλοι οι χρήστες θα λάβουν Email με τα LOGIN στοιχεία, του λογαριασμού τους για χρήση από τον υπολογιστή και την EVCOFFICE εφαρμογή.
Για προγραμματισμό επιτραπέζιων συσκευών  γραφείου: Δείτε</b>
                  </li>
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "voicemail"}
          onChange={() => this.handleChange("voicemail", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="voicemail"
            id="voicemail"
          >
            <Typography>
             Πως προσθέτω <b>Αυτόματο Τηλεφωνητή</b>;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="voicemail">
              <ol>
                  <li>Από το μενού <b>Επιχείρηση</b> (1) διαλέξτε την <b>Αυτόματη Υποδοχή</b> (2).
				  <br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice11.jpg")}
                      title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                    <br/>
                    <br/>
                  </li>
                  <li><b>‘+’</b> Προσθέστε ένα σενάριο αποδοχής των κλήσεων<br/></li>
                  <li>Συμπληρώστε όλα τα απαιτούμενα πεδία, δημιουργώντας το σενάριο απάντησης κλήσεων και πατήστε <b>ΑΠΟΘΗΚΕΥΣΗ</b>. <br/><br/>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      image={require("../../assets/img/faq/faqevcoffice12.jpg")}
                      title="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                    />
                  </li>
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "freecall"}
          onChange={() => this.handleChange("freecall", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="freecall"
            id="freecall"
          >
            <Typography>Πως μπορώ να <b>καλέσω ΔΩΡΕΑΝ</b> με την Hellasfon;</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="freecall">
                Η Hellasfon δημιουργεί το μεγαλύτερο δίκτυο δωρεάν κλήσεων στην
                Ελλάδα.
                <p>
                  Για πρώτη φορά στην Ελλάδα, οι ΗΟΜΕ πελάτες της Hellasfon
                  μπορούν να κάνουν κλήσεις, Video-κλήσεις και να στέλνουν
                  μηνύματα ,σε άλλους Hellasfon συνδρομητές, ΔΩΡΕΑΝ και για
                  πάντα.
                </p>
                <br /> Με την συνδρομή σας στην Hellasfon, κατεβάζετε την
                Hellasfon εφαρμογή. <br />Η HF εφαρμογή συγχρονίζει με τις
                επαφές στο κινητό σας, αναγνωρίζοντας αυτόματα άλλους Hellasfon
                συνδρομητές.
                <br />
                Από την Hellasfon εφαρμογή καλείτε / δέχεστε κλήσεις, εντός και
                εκτός σπιτιού σαν μια επέκταση της σταθερής συσκευής σας. Επίσης
                κάνετε δωρεάν chat, και video-κλήσεις με άλλους Hellasfon
                συνδρομητές, για πάντα.
                <br />
                Από την συσκευή στο σπίτι, όλες οι κλήσεις σε άλλους Hellasfon
                συνδρομητές είναι για πάντα ΔΩΡΕΑΝ.
                <br />
                Μπορείτε να λαμβάνετε και να πραγματοποιείτε κλήσεις
                χρησιμοποιώντας την Hellasfon εφαρμογή από το κινητό, το tablet
                και από τον υπολογιστή σας. Για να έχετε πρόσβαση σε όλες τις
                δωρεάν προηγμένες υπηρεσίες κάντε log in στο{" "}
                <a href="https://myaccount.hellasfon.com/HF">MYACCOUNT</a>.
                <br />
                <br />
                <p>
                  Οι BUSINESS πελάτες, έχουν απεριόριστες ενδοεταιρικές κλήσεις
                  από το κινητό τους και την EVCOFFICE εφαρμογή ανεξαρτήτως
                  πάροχο κινητής τηλεφωνίας. Η εφαρμογή είναι συμβατή με όλα τα
                  δίκτυα κινητής τηλεφωνίας παγκοσμίως προσφέροντας απεριόριστη
                  ενδοεπικοινωνία σε όλους τους χρήστες. Η EVCOFFICE εφαρμογή
                  ειναι έτοιμη για χρήση και δέν χρεάζεται καμία
                  παραμετροποίηση. Με το EVCOFFICE τηλεφωνικό κέντρο,
                  δέχεσαι / κάνεις κλήσεις, στέλνεις μηνύματα, και VIDEO, από το
                  κινητό, την συσκευή του γραφείου, και τον υπολογιστή σου. Μια
                  ολοκληρωμένη λύση για κάθε επιχείρηση.
                </p>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "pbx-setup"}
          onChange={() => this.handleChange("pbx-setup", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="pbx-setup"
            id="pbx-setup"
          >
            <Typography>
              Πως να <b>παραμετροποιήσω το EVCOffice Τηλεφωνικό Κέντρο</b> ;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="pbx-setup">
                <YouTube
                  videoId="WNzR85Wcf6k"
                  opts={opts}
                  // https://github.com/tjallingt/react-youtube
                />
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "provisioning"}
          onChange={() => this.handleChange("provisioning", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="provisioning"
            id="provisioning"
          >
            <Typography>
              Πως μπορώ να <b>προγραμματίσω τις Yealink και Grandstream</b> τηλεφωνικές
              συσκευές που ήδη διαθέτω για το EVCOFFICE τηλεφωνικό κέντρο;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="provisioning">
                Ολες οι συσκευές που αγοράστηκαν από την Hellasfon, έρχονται ηδη
                προγραματισμένες και έτοιμες για χρήση. Εσείς απλά τις
                συνδέετε στο ρούτερ σας. Εάν έχετε δικές σας VOIP τηλεφωνικές
                συσκευές και επιθυμείτε να τις προγραμματίσετε (provisioning),
                για το EVCOFFICE τηλεφωνικό κέντρο, ακολουθήστε
                της παρακάτω οδηγίες:<br></br><br></br>
				Για να προγραμματίσετε την <b>Yealink</b> συσκευή σας:
                <ol>
                  <li>
                    Πηγαίνετε στο webinterface της συσκευή σας . Στο tab <b>security</b> (1)
                    , <b>trusted certificates</b> (2), επιλέξτε τις παρακάτω ρυθμίσεις:
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      height="800"
                      image={require("../../assets/img/faq/sec9a.jpg")}
                      title="Contemplative Reptile"
                    />
                  </li>
                  <br></br><li>
				  <br></br>
                    Έπειτα στο "settings" και "auto provision", και συμπληρώσετε
                    το "server url:
                    <strong>
                      " https://sip3.easyvoipcall.com:442/ps/
                    </strong><br></br>
                    <CardMedia
                      component="img"
                      alt="Hellasfon Κινητή, Σταθερή Τηλεφωνία. Ολοκληρωμένες Υπηρεσίες Τηλεπικοινωνιών"
                      height="800"
                      image={require("../../assets/img/faq/auto9b.jpg")}
                      title="Contemplative Reptile"
                    />
                  </li>
                </ol>
                Για συσκευές Grandstream: <br></br>
				Από τον υπολογιστή σας, πηγαίνετε στο <b>"settings tab"</b> της συσκευής . 
				Συμπληρώστε το AUTO PROVISIONING url :{" "}
                <strong>https://sip3.easyvoipcall.com:442/ps/</strong>
                Συμπληρώστε το <b>MAC ID</b> αριθμό της συσκευής και κάντε <b>επανεκκίνηση</b>.
                Καλέστε την υποστήριξη της <b>HELLASFON - 8012221001 ή στο
                2811300307</b> , και δηλώστε το MAC ID κάθε συσκευής. H συσκευή θα
                προγραμματιστεί αυτόματα εντός λίγων λεπτών και είναι έτοιμη για
                χρήση!
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "calls"}
          onChange={() => this.handleChange("calls", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="calls"
            id="calls"
          >
            <Typography>
              Πως μπορώ να κάνω <b>κλήσεις εκτός προγράμματος</b> με το EVCOFFICE;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="calls">
                Με την εγγραφή σας στο EVCOFFICE έχετε απεριόριστες ενδοεταιρικές
                κλήσεις, CHAT και VIDEOκλήσεις.
                <br />
                Με την εγγραφή σας, μπορείτε εύκολα να προσθέσετε προγράμματα
                χρόνου ομιλίας για μεγαλύτερη εξοικονόμηση χρημάτων <br />
                ή να επιλέξτε χρονοχρέωση, χωρίς την αγορά πρόσθετου πακέτου. Η επιλογή είναι δική
                σας , και μπορείτε κάθε μήνα να προσαρμόσετε <br />
                το πρόγραμμά βάση των αναγκών σας. Τα συνδρομητικά πακέτα χρόνου
                ομιλίας χρεώνονται μηνιαίως. Για να κάνετε <br />
                κλήσεις πέρα του προγράμματος/πακέτου ομιλίας, θα πρέπει να
                έχετε προσθέσει μονάδες στον λογαριασμό σας. Οι μονάδες <br />
                δεν λήγουν ποτέ και είναι πάντα διαθέσιμες για δική σας χρήση.
                Είναι άλλος ένας έξυπνος τρόπος να κρατάτε έλεγχο <br />
                στα έξοδα επικοινωνίας σας . Από την EVCOFFICE πύλη πελατών μπορείτε
                να προσθέσετε μονάδες για κλήσει εκτός πακέτου. <br />
                Οι μονάδες πιστώνονται αμέσως χωρίς να ψάχνεις σημεία πώλησης.
                Δεχόμαστε όλες τις πιστωτικές κάρτες και Paypal. <br />
                Πλέον μπορείτε να πραγματοποιήσετε κλήσεις εκτός προγράμματος,
                να εξοφλείτε τον λογαριασμό σας και να κάνετε χρήση <br />
                της σταθερής τηλεφωνίας από το κινητό σας, από οποιοδήποτε
                σημείο στον κόσμο , χωρίς επιπλέον χρεώσεις και περιαγωγή Roaming.{" "}
                <br />
                <br />
                <br />
                <b>Στην Hellasfon οι μονάδες σας δεν λήγουν ποτέ.</b>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "what-is-evcoffice"}
          onChange={() => this.handleChange("what-is-evcoffice", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="what-is-evcoffice"
            id="what-is-evcoffice"
          >
            <Typography>
              Τι είναι το <b>EVCOFFICE Tηλεφωνικό κέντρο;</b>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="what-is-evcoffice">
                Το <b>EVCOFFICE</b> είναι το πιο ολοκληρωμένο τηλεφωνικό κέντρο στην
                αγορά. Σας προσφέρει καινοτόμες υπηρεσίες.<br></br>
				Αντικαθιστά οποιοδήποτε παραδοσιακό τηλεφωνικό κέντρο, παρέχοντας 
				στην επιχείρηση σας ευελιξία με επιπλέον δυνατότητες : την <b>μείωση των δαπανών </b>
				και την <b>αύξηση της παραγωγικότητας</b>. Το EVCOFFICE τηλεφωνικό κέντρο
                προσφέρει <b>100% διαθεσιμότητα</b> σε όλες τις κλήσεις, χωρίς περιορισμούς. 
				<br></br><br></br>
				Όλοι οι χρήστες του κέντρου έχουν την δική τους γραμμή για να μπορούν να καλούν ταυτόχρονα χωρίς να περιοριστεί η διαθεσιμότητα
                <br />
                των εισερχομένων κλήσεων. Διαθέτει ΔΩΡΕΑΝ εφαρμογές , για
                αδιάλειπτη επικοινωνία και μέγιστη οικονομία σε όλες τις
                κλήσεις. <br />
                Ενσωματώνει την σταθερή και κινητή τηλεφωνία, με σκοπό την
                αύξηση της παραγωγικότητας, και τη δραστική μείωση των δαπανών σας.
                <br /><br></br>
                Διαλέξτε το πακέτο που σας ταιριάζει, και πλέον ,μπορείτε να
                απαντάτε και να καλείτε από την EVCOFFICE εφαρμογή, τον
                υπολογιστή σας και από την συσκευή γραφείου. Εντός και εκτός
                γραφείου, από οποιοδήποτε σημείο στον κόσμο, χωρίς πρόσθετες χρεώσεις. 
				Διαθέτει σε όλους τους χρήστες VIDEO τηλεδιάσκεψη με απεριόριστες συνεδριάσεις.
                <br />
                <ol>
                  <li>
                    <b>Προσθέστε</b> άδειες χρήσης με όλες τις προηγμένες υπηρεσίες
                    EVCOFFICE με <b>0,75€/μήνα/άδεια</b>. Φτιάξε το δικό σου κέντρο
                    όπως το θέλεις, ευέλικτο , οικονομικό και με όσους χρήστες
                    θέλεις. Πρόσθεσε προγράμματα χρόνου ομιλίας για μεγαλύτερη
                    οικονομία ή συμπλήρωσε την εγγραφή σου με μονάδες που δεν λήγουν
                    ποτέ, για κλήσεις και εκτός προγράματος. Η επιλογή είναι δική
                    σου, και έχεις την δυνατότητα να την αλλάξεις ανά πάσα
                    στιγμή.
                  </li>
                  Αντικαθιστά οποιοδήποτε παραδοσιακό κέντρο, παρέχοντας στην
                  επιχείρηση σας επιπλέον δυνατότητες, την μείωση των δαπανών
                  και την αύξηση της παραγωγικότητας.
                  <br />
                </ol>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "provision"}
          onChange={() => this.handleChange("provision", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="provision"
            id="provision"
          >
            <Typography>
              Πως μπορώ να προγραμματίσω <b>(provisioning)</b> τις τηλεφωνικές συσκευές
              που διαθέτω;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="provision">
                Όλες οι συσκευές από την Hellasfon, έρχονται ήδη
                προγραμματισμένες και έτοιμες για χρήση. Εσείς απλά τις
                συνδέετε στο ρούτερ. Εάν διαθέτετε δικές σας VOIP τηλεφωνικές
                συσκευές και επιθυμείτε να τις προγραμματίσετε (provisioning),
                για το EVCOFFICE τηλεφωνικό κέντρο της HELLASFON, Πολύ απλά
                μπορείτε να καλέσετε στο τμήμα support της HELLASFON και θα σας
                βοηθήσουν σε αυτό . Η διαδικασία απαιτεί ελάχιστο χρόνο.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "nointernet"}
          onChange={() => this.handleChange("nointernet", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="nointernet"
            id="nointernet"
          >
            <Typography>
              <b>Δεν έχω Internet ή σήμα WiFi</b> , πως μπορώ να καλέσω από το κινητό
              μου χρησιμοποιώντας τις υπηρεσίες της Hellasfon;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="nointernet">
                Δεν χρειάζεται να πανικοβάλλεστε η Hellasfon έχει την λύση!
                <ol>
                  <li>
                    Ακόμα και αν <b>ΔΕΝ</b> έχετε πρόσβαση σε Internet ή WiFi μπορείτε
                    να κάνετε κλήση απευθείας από το κινητό σας με τις εφαρμογές
                    της HELLASFON HF και EVCOFFICE. Ενεργοποιώντας την υπηρεσία
                    <b> Call Connection </b>(από τις ρυθμίσεις της εφαρμογής) , η κλήση σας
                    πραγματοποιείται χρησιμοποιώντας την αυτόματη τηλεφωνήτρια.
                    Η εφαρμογή πραγματοποιεί μια αναπάντηση κλήση μέσω του
                    δικτύου κινητής τηλεφωνίας στους κόμβους μας.
                    Αμέσως μετά , θα λάβετε μια κλήση απο την Αυτόματη τηλεφωνήτρια για να σας
                    συνδέσει στον αριθμό που επιθυμείτε.{" "}
                  </li>
                </ol>
                Για να κάνετε χρήση της υπηρεσίας <b>Call Connection</b>, πρέπει να έχετε ενεργό
                αριθμό κινητής τηλεφωνίας. <br />
                <br />
                <b>Με την υπηρεσία Call Connection, ΔΕΝ ΥΠΑΡΧΟΥΝ χρεώσεις στην
                κινητή τηλεφωνία! </b>ΕΜΕΙΣ σας καλούμε στο κινητό και ΕΜΕΙΣ σας συνδέουμε
                στον αριθμό που επιθυμείτε.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "foritotita"}
          onChange={() => this.handleChange("foritotita", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="foritotita"
            id="foritotita"
          >
            <Typography>
              Πως μπορώ να <b>μεταφέρω τον τηλεφωνικό μου αριθμό</b> στην Hellasfon;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="foritotita">
                H διαδικασία μεταφοράς τηλεφωνικού αριθμού στην HellasFon είναι
                πάρα πολύ απλή. Κατεβάστε και συμπληρώσετε την{" "}
                <a href="/docs/hellasfon-foritotita-gr.pdf">
                  αίτηση φορητότητας
                </a>{" "}
                . Ακολουθήστε τις{" "}
                <a href="/docs/hellasfon-foritotita-gr-1.jpg">παρακάτω οδηγίες</a>. Στη
                συνέχεια στείλτε μας την συμπληρωμένη αίτηση μαζι με τα στοιχεία
                ταυτοποίησης μέσω email στο {" "}
                <a href="mailto:support@hellasfon.com">
                  support@hellasfon.com, ή με ΦΑΞ 2811300308.
                </a>
                Για την μεγαλύτερη ευκολία σας, τραβήξτε φωτογραφία την συμπληρωμένη
                αίτηση και την ταυτότητά σας, και στείλτε τα σαν συνημμένα από
                το μενού feedback, μέσα από τη HELLASFON (HF) εφαρμογή.
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
		<ExpansionPanel
          expanded={expanded === "arthro11"}
          onChange={() => this.handleChange("arthro11", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="arthro11"
            id="arthro11"
          >
            <Typography>
              Πως μπορώ να <b>ενταχθώ/απενταχθώ</b> στο <b>μητρώο του Άρθρου 11</b> του νόμου Ν.3471/2006 ;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="arthro11">
                Η διαδικασία που πρέπει να ακολουθήσετε είναι πολύ απλή. 
                Κατεβάστε και συμπληρώστε την 
				<a href="/docs/MHTROO11GR.pdf"> Αίτηση Ένταξης/Απένταξης στο Μητρώο 11 </a> του νόμου Ν.3471/2006 
				και αποστείλτε μας την μέσω Fax στο <b>2811-300308</b> ή μέσω e-mail στο 
				{" "}
                <a href="mailto:support@hellasfon.com">support@hellasfon.com</a>{" "}
		     </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
		
		<ExpansionPanel
          expanded={expanded === "panel30"}
          onChange={() => this.handleChange("panel30", expanded)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel30d-content"
            id="panel26d-header"
          >
            <Typography>
              Πως μπορώ να <b>κατεβάσω</b> και να χρησιμοποιήσω το <b>Zoiper ?</b> (softphone) ;
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography component={"span"}>
              <div className="panel27">
                Η διαδικασία που πρέπει να ακολουθήσετε είναι πολύ απλή! <br></br>
                Πατήστε στο σύνδεσμο για να δείτε :  
				<a href="https://www.zoiper.com/en/page/4c709eec7c406f250a9f146f77909c4c?u=&h=&p=&o=&t=&a=&tr=">Οδηγίες για το κατέβασμα και ρύθμιση του Zoiper (συσκευές Android και iOS)</a>
				
		     </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

FAQEVCOFFICE.propTypes = {
  classes: PropTypes.object,
};

export default FAQEVCOFFICE;
